<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <ListingComponent ref="ActionsLog"
                      class="bg-white"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
  </div>
</template>

<script>
  import ListingComponent from '@/components/listing/ListingComponent'
  import moment from 'moment';
  export default {
    name: 'ActionsLog',
    data () {
      return {
        columnConfig: [
          {
            columnCode: 'CREATOR',
            columnName: 'Creator',
            value: item => {
              return item.creator
            }
          },
          {
            columnCode: 'PHONE',
            columnName: 'Phone',
            align: 'left',
            value: item => {
              return item.phone
            }
          },
          {
            columnCode: 'PLACE',
            columnName: 'Place',
            align: 'left',
            value: item => {
              return item.place
            }
          },
          {
            columnCode: 'TYPE',
            columnName: 'Type',
            align: 'left',
            value: item => {
              return item.xtype;
            }
          },
          {
            columnCode: 'COMMENT',
            columnName: 'Comment',
            align: 'left',
            value: item => {
              return item.comment;
            }
          },
          {
            columnCode: 'ACTION',
            columnName: 'Action',
            align: 'left',
            value: item => {
              return item.rating;
            }
          },
          {
            columnCode: 'REVIEW_DATE',
            columnName: 'Review Date',
            align: 'left',
            value: item => {
              return moment(item.reviewDate).format('MM/DD/YYYY hh:mm');
            }
          }
        ]
      }
    },
    methods: {
      requestProvider (payload) {
        return this.axios.get('public/place/log-history', payload)
      },
      addNewButton () {
        return null
      },
    },
    components: {
      ListingComponent
    }
  };
</script>

<style scoped>

</style>